// React
import React,
	   {Component} from "react";
import {Nav,
		Navbar} from "react-bootstrap";
import {faCocktail,
		faEnvelope,
		faHome,
		faKey,
		faLock,
		faPencilAlt,
		faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// App
import LOGO from "../../assets/images/G-UL-Logo.png";


class Navigation extends Component
{
	render()
	{
		return (
			<Navbar className="gs-navbar"
					fixed="top">
				<Navbar.Brand href="/">
					<img alt="G-UL Logo"
						 height="60"
						 src={LOGO}
						 width="60" />
				</Navbar.Brand>

				<Nav>
					<Nav.Item>
						<Nav.Link href="/">
							<FontAwesomeIcon icon={faHome} /> Welcome
						</Nav.Link>
					</Nav.Item>

					<Nav.Item>
						<Nav.Link href="/resume">
							<FontAwesomeIcon icon={faSearch} /> Resume
						</Nav.Link>
					</Nav.Item>

					<Nav.Item>
						<Nav.Link href="/courses">
							<FontAwesomeIcon icon={faPencilAlt} /> Courses
						</Nav.Link>
					</Nav.Item>

					<Nav.Item>
						<Nav.Link href="/contact-me">
							<FontAwesomeIcon icon={faEnvelope} /> Contact
						</Nav.Link>
					</Nav.Item>

					<Nav.Item>
						<Nav.Link href="/relaxation">
							<FontAwesomeIcon icon={faCocktail} /> Relaxation
						</Nav.Link>
					</Nav.Item>
				</Nav>

				<Nav>
					<Nav.Item>
						<Nav.Link href="/register">
							<FontAwesomeIcon icon={faLock} /> Register
						</Nav.Link>
					</Nav.Item>

					<Nav.Item>
						<Nav.Link href="/login">
							<FontAwesomeIcon icon={faKey} /> Login
						</Nav.Link>
					</Nav.Item>
				</Nav>
			</Navbar>
		)
	}
}


export default Navigation;
