// NOTABLE COURSES
export const NOTABLE_COURSES = [
	{"courseCode": "CSC108H1",
	 "description": "Programming in a language such as Python.",
	 "keywords": "Classes, Objects, Methods, Searching, Sorting and Complexity",
	 "title": "Introduction to Computer Programming"},
	{"courseCode": "CSC148H1",
	 "description": "Abstract data types and data structures for implementing them.",
	 "keywords": "OOP, Recursion, Linked List, BST",
	 "title": "Introduction to Computer Science"},
	{"courseCode": "CSC165H1",
	 "description": "Introduction to abstraction and rigour (logical notation and reasoning).",
	 "keywords": "Logical Notation and Reasoning, Big-O Notation",
	 "title": "Mathematical Expression and Reasoning for Computer Science"},
	{"courseCode": "CSC207H1",
	 "description": "An introduction to software design and tools using a statically-typed object-oriented programming language.",
	 "keywords": "Java, JUnit, Android, Design Pattern",
	 "title": "Software Design"},
	{"courseCode": "CSC209H1",
	 "description": "Software techniques in a Unix-style environment, using scripting languages and a machine-oriented programming language.",
	 "keywords": "C, Processes, Pipes, Shell, Basic Network",
	 "title": "Software Tools and Systems Programming"},
	{"courseCode": "CSC236H1",
	 "description": "The application of logic and proof techniques to Computer Science.",
	 "keywords": "Induction, Iterative/Recursive Correctness Proofs",
	 "title": "Introduction to the Theory of Computation"},
	{"courseCode": "CSC258H1",
	 "description": "Computer structures, machine languages, instruction execution, addressing techniques, and digital representation of data.",
	 "keywords": "Circuits, Floating Point, Memory",
	 "title": "Computer Organization"},
	{"courseCode": "CSC263H1",
	 "description": "Algorithm analysis: worst-case, average-case, and amortized complexity.",
	 "keywords": "Graph, MST, Randomized Quick Sort, Balanced Trees",
	 "title": "Data Structures and Analysis"},
	{"courseCode": "CSC343H1",
	 "description": "Introduction to database management systems.",
	 "keywords": "Relational Algebra, Querying, PSQL, SQLite",
	 "title": "Introduction to Databases"},
	{"courseCode": "MAT135Y1",
	 "description": "Integration and fundamental theorem, applications, Series, Introduction to differential equations.",
	 "keywords": "Integration, Series, Differentiation",
	 "title": "Calculus"},
	{"courseCode": "MAT223H1",
	 "description": "Systems of linear equations, matrix algebra, etc",
	 "keywords": "Matrix, Linear Transformations, Vector Spaces",
	 "title": "Linear Algebra I"},
	{"courseCode": "STA247H1",
	 "description": "Introduction to the theory of probability.",
	 "keywords": "Conditional Probability, Normal, Exponential, Binomial, Poisson Distributions",
	 "title": "Probability with Computer Applications"},
	{"courseCode": "CSB332H1",
	 "description": "Examination of all aspects of the synapse in both the peripheral and central nervous systems of invertebrates and vertebrates.",
	 "keywords": "Synapse Formation, Synaptic Transmission, Synaptic Plasticity, Learning and Memory, and Neurological Disorders.",
	 "title": "Neurobiology of the Synapse"},
	{"courseCode": "CSB351Y1",
	 "description": "An introduction to basic and medical virology.",
	 "keywords": "Virus, HIV",
	 "title": "Introductory Virology"},
	{"courseCode": "EEB460H1",
	 "description": "Processes of evolution at the molecular level, and the analysis of molecular data.",
	 "keywords": "Gene Structure, Nucleotide Sequence Evolution, Phylogeny Construction",
	 "title": "Molecular Evolution and Genomics"}
];
