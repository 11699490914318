// React
import React,
	   {Component} from "react";
import {Button,
		Col,
		Container,
		Row} from "react-bootstrap";

// Projects
import beachSound from "../../assets/sounds/beach.mp3";
import beachSVG from "../../assets/svg/beach.svg";
import beachVideo from "../../assets/video/beach.mp4";
import pauseSVG from "../../assets/svg/pause.svg";
import playSVG from "../../assets/svg/play.svg";
import rainSound from "../../assets/sounds/rain.mp3";
import rainSVG from "../../assets/svg/rain.svg";
import rainVideo from "../../assets/video/rain.mp4";



class Relaxation extends Component
{
	constructor()
	{
		super();
		this.state = {"isPlaying": false,
					  "playSVG": playSVG};
		this.handleBeach = this.handleBeach.bind(this);
		this.handlePlaying = this.handlePlaying.bind(this);
		this.handleRain = this.handleRain.bind(this);
		this.updateAudioNVideo = this.updateAudioNVideo.bind(this);
	}


	handleBeach(event)
	{
		event.preventDefault();
		this.updateAudioNVideo(true);
	}


	handlePlaying(event)
	{
		event.preventDefault();
		const song = document.querySelector(".song"),
			  video = document.querySelector(".video");
		if (song.paused)
		{
			song.play();
			video.play();
			this.setState({"isPlaying": true,
						   "playSVG": pauseSVG});
		}
		else
		{
			song.pause();
			video.pause();
			this.setState({"isPlaying": false,
						   "playSVG": playSVG});
		}
	}


	handleRain(event)
	{
		event.preventDefault();
		this.updateAudioNVideo(false);
	}


	updateAudioNVideo(flag)
	{
		const song = document.querySelector(".song"),
			  video = document.querySelector(".video");
		if (flag)
		{
			song.src = beachSound;
			video.src = beachVideo;
		}
		else
		{
			song.src = rainSound;
			video.src = rainVideo;
		}

		this.setState({"isPlaying": true,
					   "playSVG": pauseSVG});
		song.play();
		video.play();
	}


	render()
	{
		return (
			<div className="relaxation-body">
				<br />

				<Container>
					<Row>
						<Col md={12}>
							<div id="monitor">
								<div id="monitorscreen">
									<video className="video"
										   loop>
										<source src={rainVideo}
												type="video/mp4" />
									</video>
								</div>
							</div>
						</Col>

						<Col className="text-center"
							 md={4}>
							<Button className="beach-button"
									onClick={this.handleBeach}>
								<img alt=""
									 src={beachSVG}>
								</img>
							</Button>
						</Col>

						<Col className="text-center"
							 md={4}>
							<audio className="song"
								   loop>
								<source src={rainSound} />
							</audio>

							<Button className="play-button"
									onClick={this.handlePlaying}>
								<img alt=""
									 className="play"
									 src={this.state.playSVG}>
								</img>
							</Button>
						</Col>

						<Col className="text-center"
							 md={4}>
							<Button className="rain-button"
									onClick={this.handleRain}>
								<img alt=""
									 src={rainSVG}>
								</img>
							</Button>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
};


export default Relaxation;
