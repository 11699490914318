// React
import React,
	   {Component} from "react";
import {Col,
		Container,
		Row} from "react-bootstrap";

// Project
import PageNotFound from "../../assets/images/page-not-found.jpg";


class NotFound extends Component
{
	render()
	{
		return (
			<Container>
				<Row>
					<Col md={12}>
						<div className="page-not-found">
							<img alt="pageNotFound"
								 src={PageNotFound}
								 width="300"
								 height="300" />

							<br />

							<span className="not-found-span">
								Page Not Found
							</span>
						</div>
					</Col>
				</Row>
			</Container>
		)
	}
}


export default NotFound;
