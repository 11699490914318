// Base URL
export const BASE_URL = "https://g-ul.me"

// CSRF URL
export const CSRF_URL = "/csrf";

// DEV BASE URL
export const DEV_BASE_URL = "http://127.0.0.1:8000";

// EMAIL API
export const EMAIL_URL = "/api/account/contact_me/"

// IP
export const IP_ULR = "https://api.ipify.org/?format=json";

// VISTITOR STATS API URL
export const VISITOR_API = "/api/country/ip_count_update/"

// VISTITOR STATS FETCHING API URL
export const VISITOR_FETCHING_API = "/api/country/fetch_ip_stats/"
