// React
import React,
	   {Component} from "react";


class Loader extends Component
{
	render()
	{
		return (
			<div className="gs-loader"></div>
		);
	}
}


export default Loader;
