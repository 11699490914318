// React
import React,
	   {Component} from "react";
import {Col,
		Container,
		Row,
		Table} from "react-bootstrap";
import cookies from "react-cookies";
import {faHeart} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

// Project
import {BASE_URL,
		VISITOR_FETCHING_API} from "../../constants/baseUrl.js";
import Loader from "../../components/Loader.js";


class Visitor extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {"isLoaded": false,
					  "visitorStats": undefined};
	}


	componentDidMount()
	{
		const csrfToken = cookies.load("csrfToken");
		const token = cookies.load("token");
		let header = {"Accept": "application/json",
					  "Content-Type": "application/json",
					  "Credentials": "same-origin"}

		if (csrfToken) header["X-CSRFTOKEN"] = csrfToken;

		if (token) header["Authorization"] = "Token " + token;

		let requestConfig = {"headers": header,
							 "method": "GET"};
		let url = BASE_URL + VISITOR_FETCHING_API;

		fetch(
			url,
			requestConfig
		).then(
			response => response.json()
		).then((data) => {
			const rows = data;
			const keys = ["name",
						  "continent",
						  "count"];
			var contents = rows.map(
				(row, i) => <tr key={i}>
					{keys.map((key, j) => <td key={j}>{row[key]}</td>)}
				</tr>
			);
			this.setState({"isLoaded": true,
						   "visitorStats": contents});
		}).catch((data) => {
			console.log(data);
		});
	}


	render()
	{
		return (
			<div className="course-info-body">
				<Container>
					<br />
					<Row>
						<Col md={12}>
							<h2>
								<FontAwesomeIcon icon={faHeart} /> Visitor Stats
							</h2>
						</Col>
					</Row>

					<Row>
						<Col md={12}>
							<p>
								Thank you for visiting my websites!
							</p>
						</Col>
					</Row>

					<Row>
						<Col md={12}>
							{
								!this.state.isLoaded &&
								<Loader />
							}

							{
								this.state.isLoaded &&
								<Table className="g-table"
									   striped
									   bordered
									   hover>
									<thead className="g-table-header">
										<tr>
											<th>Country</th>
											<th>Continent</th>
											<th># of Visits</th>
										</tr>
									</thead>

									<tbody>
										{this.state.visitorStats}
									</tbody>
								</Table>
							}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
};


export default Visitor;
