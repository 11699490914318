// React
import React,
	   {Component} from "react";
import {Col,
		Container,
		Row} from "react-bootstrap";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Project
import resume from "../../assets/pdf/Resume.pdf";
import resume01 from "../../assets/images/Resume-01.png";
import resume02 from "../../assets/images/Resume-02.png";


class Resume extends Component
{
	render()
	{
		return (
			<div className="resume">
				<Container>
					<Row>
						<Col className="text-center"
							 md={12}>
							<img alt="resume-1"
								 className="resume-pdf"
								 src={resume01}>
							</img>
						</Col>

						<Col className="text-center"
							 md={12}>
							<img alt="resume-2"
								 className="resume-pdf"
								 src={resume02}>
							</img>
						</Col>

						<br/>

						<Col className="text-center"
							 md={12}>
							<a className="btn
										  btn-light
										  resume-download"
							   download="Um, SeungHwan.pdf"
							   href={resume}
							   type="button">
								<FontAwesomeIcon icon={faDownload} /> Download
							</a>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
};


export default Resume;
