// React
import React,
	   {Component} from "react";
import {Col,
		Container,
		Row,
		Table} from "react-bootstrap";
import {faPencilRuler} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Projects
import {NOTABLE_COURSES} from "../../constants/coursesConstants.js";


class Course extends Component
{
	render()
	{
		const keys = ["courseCode",
					  "title",
					  "description",
					  "keywords"];
		var contents = NOTABLE_COURSES.map(
			(row, i) => <tr key={i}>
				{keys.map((key, j) => <td key={j}>{row[key]}</td>)}
			</tr>
		);

		const overFlow = {"marginRight": "15px",
						  "overflow": "auto"};


		return (
			<div className="course-info-body">
				<Container>
					<br />
					<Row>
						<Col md={12}>
							<h2>
								<FontAwesomeIcon icon={faPencilRuler} /> University Courses
							</h2>
						</Col>
					</Row>

					<Row>
						<Col md={12}>
							<p>
								I finished a major in Human Biology at the <a href="https://www.utoronto.ca/">University of Toronto</a>, St. George campus.
							</p>

							<p>
								Below table briefly explains about notable courses that I had taken.
							</p>
						</Col>
					</Row>

					<Row>
						<Col md={12}
							 style={overFlow}>
							<Table className="g-table"
								   striped
								   bordered
								   hover>
								<thead className="g-table-header">
									<tr>
										<th>Code</th>
										<th>Title</th>
										<th>Description</th>
										<th>Keywords</th>
									</tr>
								</thead>

								<tbody>
									{contents}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
};


export default Course;
