// React
import React,
	   {Component} from "react";
import {Button,
		Card,
		Col,
		Container,
		Form,
		Row} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import cookies from "react-cookies";
import {faMailBulk,
		faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Project
import {BASE_URL,
		EMAIL_URL} from "../../constants/baseUrl.js";
import FieldGroup from "../../components/FieldGroup.js";
import {validateEmail} from "../../utils/authUtils.js";


class ContactMe extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {contents: "",
					  from: "",
					  name: "",
					  subject: "",
					  alert: null};
		this.close = this.close.bind(this);
		this.getBasicValidation = this.getBasicValidation.bind(this);
		this.getValidationEmail = this.getValidationEmail.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.isDisabledButton = this.isDisabledButton.bind(this);
		this.setAlert = this.setAlert.bind(this);
	}


	close(flag)
	{
		this.setState({alert: null});
		if (flag)
		{
			this.props.history.push("/");
		}
		else
		{
			window.location.reload();
		}
	}


	getBasicValidation(name)
	{
		if (!name ||
			!name.length)

			return false;

		return true;
	}


	getValidationEmail(email)
	{
		return validateEmail(email);
	}


	handleChange(event)
	{
		event.preventDefault();
		this.setState({[event.target.name]: event.target.value});
	}


	handleClick(event)
	{
		event.preventDefault();
		const csrfToken = cookies.load("csrfToken");
		const token = cookies.load("token");
		let header = {"Accept": "application/json",
					  "Content-Type": "application/json",
					  "Credentials": "same-origin"}

		if (csrfToken) header["X-CSRFTOKEN"] = csrfToken;

		if (token) header["Authorization"] = "Token " + token;

		const body = {"contents": this.state.contents,
					  "from": this.state.from,
					  "name": this.state.name,
					  "subject": this.state.subject}
		let requestConfig = {"headers": header,
							 "method": "POST"};
		requestConfig["body"] = JSON.stringify(body);
		let url = BASE_URL + EMAIL_URL;
		fetch(
			url,
			requestConfig).then(
				response => response.json()
			).then((data) => {
				this.setAlert(true,
							  data.message);
			}).catch((data) => {
				this.setAlert(false,
							  data.message);
			}
		);
	}


	isDisabledButton()
	{
		return !(this.state.contents &&
				 this.getValidationEmail(this.state.from) &&
				 this.state.name &&
				 this.state.subject);
	}


	setAlert(flag,
			 msg)
	{
		if (flag)
		{
			this.setState({
				alert: (
					<SweetAlert confirmBtnText="Confirm"
								confirmBtnBsStyle="success"
								onConfirm={() => this.close(flag)}
								title="Success"
								success>
						{msg}
					</SweetAlert>
				)
			});
		}
		else
		{
			this.setState({
				alert: (
					<SweetAlert confirmBtnText="Confirm"
								confirmBtnBsStyle="danger"
								onConfirm={() => this.close(flag)}
								title="Error"
								warning>
						{msg}
					</SweetAlert>
				)
			});
		}
	}


	render()
	{
		return (

			<div className="contact-me">
				<br/>
				<br/>
				<br/>
				<Container>
					<Card>
						<Card.Title className="gs-card-title">
							<div className="gs-card-title-text">
								<FontAwesomeIcon icon={faPaperPlane} /> Please feel free to contact me :)
							</div>
						</Card.Title>

						<Card.Body>
							<Form>
								<Row>
									<Col md={6}
										 sm={12}>
										<FieldGroup fieldGroupControlId="name"
													fieldGroupHelp="Name is required."
													fieldGroupLabel="Name"
													fieldGroupName="name"
													fieldGroupOnChange={this.handleChange}
													fieldGroupPlaceholder="Please enter your Name..."
													fieldGroupType="text"
													fieldGroupValidationState={this.getBasicValidation}
													fieldGroupValue={this.state.name} />
									</Col>

									<Col md={6}
										 sm={12}>
										<FieldGroup fieldGroupControlId="email"
													fieldGroupHelp="Email is invalid."
													fieldGroupLabel="From"
													fieldGroupName="from"
													fieldGroupOnChange={this.handleChange}
													fieldGroupPlaceholder="Please enter your Email..."
													fieldGroupType="email"
													fieldGroupValidationState={this.getValidationEmail}
													fieldGroupValue={this.state.from} />
									</Col>
								</Row>

								<Row>
									<Col md={12}>
										<FieldGroup fieldGroupControlId="subject"
													fieldGroupHelp="Subject is required."
													fieldGroupLabel="Subject"
													fieldGroupName="subject"
													fieldGroupOnChange={this.handleChange}
													fieldGroupPlaceholder="Please add Subject..."
													fieldGroupType="text"
													fieldGroupValidationState={this.getBasicValidation}
													fieldGroupValue={this.state.subject} />
									</Col>
								</Row>

								<Row>
									<Col md={12}>
										<Form.Group controlId="contents">
											<Form.Label>Content</Form.Label>
											<Form.Control as="textarea"
														  isInvalid={!this.state.contents}
														  isValid={this.state.contents}
														  name="contents"
														  onChange={this.handleChange}
														  placeholder="Please add Content... "
														  required
														  rows="10"
														  type="text"
														  value={this.state.contents} />
											{
												!this.state.contents &&
											 	<Form.Text style={{color: "#DC3545"}}>
													Content is required.
												</Form.Text>}
										</Form.Group>
									</Col>
								</Row>

								<Row>
									<Col md={12}>
										<Form.Group>
											<Button className="email-button"
													disabled={this.isDisabledButton()}
													onClick={this.handleClick}
													type="submit"
													variant="dark">
												<FontAwesomeIcon icon={faMailBulk} /> Send Email
											</Button>
										</Form.Group>
									</Col>
								</Row>
							</Form>
						</Card.Body>
					</Card>
					{this.state.alert}
				</Container>
			</div>
		);
	}
};


export default ContactMe;
