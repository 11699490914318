// React
import React,
	   {Component} from "react";
import {Form} from "react-bootstrap";


class FieldGroup extends Component
{
	constructor(props)
	{
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.getValidation = this.getValidation.bind(this);
	}


	handleChange(event)
	{
		event.preventDefault();
		this.props.fieldGroupOnChange(event);
	}


	getValidation(value)
	{
		return this.props.fieldGroupValidationState(value);
	}


	render()
	{
		return (
			<Form.Group controlId={this.props.fieldGroupControlId}>
				<Form.Label>{this.props.fieldGroupLabel}</Form.Label>
				<Form.Control as="input"
							  isInvalid={!this.getValidation(this.props.fieldGroupValue)}
							  isValid={this.getValidation(this.props.fieldGroupValue)}
							  name={this.props.fieldGroupName}
							  onChange={this.handleChange}
							  placeholder={this.props.fieldGroupPlaceholder}
							  required
							  type={this.props.fieldGroupType}
							  value={this.props.fieldGroupValue} />

				{this.props.fieldGroupHelp &&
				 !this.getValidation(this.props.fieldGroupValue) &&
				 <Form.Text style={{color: "#DC3545"}}>{this.props.fieldGroupHelp}</Form.Text>}
			</Form.Group>
		);
	}
};


export default FieldGroup;
