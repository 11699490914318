export function getAuthForm()
{
	let user = JSON.parse(localStorage.getItem("user"));
	if (user &&
		Object.keys(user).length)
	{
		return user
	}

	return {};
}


export function isAuthenticated()
{
	let user = JSON.parse(localStorage.getItem("user"));
	if (user &&
		Object.keys(user).length)
	{
		if (user["is_authenticated"])
		{
			return true;
		}
	}

	return false;
}


export function isSuperUser()
{
	let user = JSON.parse(localStorage.getItem("user"));
	if (user &&
		Object.keys(user).length)
	{
		if (user["is_superuser"])
		{
			return true;
		}
	}

	return false;
}


export function validateEmail(email)
{
	if (!email ||
		!email.length)
	{
		return false;
	}

	// var regex = /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
	var regex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

	return regex.test(email);
}
