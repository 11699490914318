// React
import React,
	   {Component} from "react";
import cookies from "react-cookies";
import {Route,
		Switch} from "react-router-dom";

// Package
import "bootstrap/dist/css/bootstrap.css";

// Apps
import "./styles/css/App.css";
import {BASE_URL,
		CSRF_URL} from "./constants/baseUrl.js";
import ContactMe from "./containers/ContactMe/ContactMe.js";	
import Course from "./containers/Course/Course.js";
import Cursor from "./containers/Cursor/Cursor.js";
import Footer from "./containers/Footer/Footer.js";
import Home from "./containers/Home/Home.js";
import Navigation from "./containers/Navigation/Navigation.js";
import NotFound from "./containers/NotFound/NotFound.js";
import Relaxation from "./containers/Relaxation/Relaxation.js";
import Resume from "./containers/Resume/Resume.js";
import Visitor from "./containers/Visitor/Visitor.js";


async function getCsrfToken()
{
	const csrfToken = cookies.load("csrfToken");
	let requestConfig = {"headers": {"Accept": "application/json",
									 "Content-Type": "application/json",
									 "Credentials": "same-origin"},
						 "method": "GET"};
	if (!csrfToken)
	{
		let url = BASE_URL + CSRF_URL;
		const response = await fetch(url,
									 requestConfig);

		const data = await response.json();
		const newCsrfToken = data.csrfToken;
		cookies.save("csrfToken",
					 newCsrfToken);
	}
}


class App extends Component
{
	componentDidMount()
	{
		getCsrfToken();
	}


	render()
	{
		return (
			<div className="App">
				<Cursor />

				<header>
					<Navigation />
				</header>

				<main className="main-page">
					<Switch>
						<Route component={Home}
							   exact
							   path="/" />

						<Route component={Course}
							   exact
							   path="/courses" />

						<Route component={Resume}
							   exact
							   path="/resume" />

						<Route component={ContactMe}
							   exact
							   path="/contact-me" />

						<Route component={Relaxation}
							   exact
							   path="/relaxation" />

						<Route component={Visitor}
							   exact
							   path="/visitor-stats" />

						<Route component={NotFound} />
					</Switch>
				</main>

				<Footer />
			</div>
		);
	}
}


export default App;
