// React
import React,
	   {Component} from "react";
import {Col,
		Container,
		Row} from "react-bootstrap";
import cookies from "react-cookies";

// Project
import {BASE_URL,
		IP_ULR,
		VISITOR_API} from "../../constants/baseUrl.js";
import me from "../../assets/images/g-ul.jpg";


class Home extends Component
{
	componentDidMount()
	{
		fetch(
			IP_ULR
		).then(
			response => response.json()
		).then((data) => {
			const csrfToken = cookies.load("csrfToken");
			const token = cookies.load("token");
			let header = {"Accept": "application/json",
						  "Content-Type": "application/json",
						  "Credentials": "same-origin"}

			if (csrfToken) header["X-CSRFTOKEN"] = csrfToken;

			if (token) header["Authorization"] = "Token " + token;

			let requestConfig = {"headers": header,
								 "method": "POST"};
			requestConfig["body"] = JSON.stringify(data);
			let url = BASE_URL + VISITOR_API;

			fetch(
				url,
				requestConfig
			).then(
				response => response.json()
			).then((data) => {

			}).catch((data) => {
				console.log(data);
			});
		}).catch((data) => {
			console.log(data);
		});
	}


	render()
	{
		return (
			<div className="welcome-body">
				<br />
				<br />

				<Container>
					<Row>
						<Col md={6}
							 sm={12}>
							<Col md={12}>
								<h2 className="seung-hwan">
									SeungHwan
								</h2>
							</Col>

							<Col md={12}>
								<h2 className="g-ul">
									G-UL
								</h2>
							</Col>

							<Col md={12}>
								<h2 className="um">
									Um
								</h2>
							</Col>

							<Col md={12}>
								<h2 className="link-g-ul">
									<a className="hello-g-ul"
									   href="https://hello.g-ul.me">
									   hello.g-ul.me
									</a>
								</h2>

								<h2 className="link-g-ul">
									<a className="metronome-g-ul"
									   href="https://metronome.g-ul.me/">
									   metronome.g-ul.me
									</a>
								</h2>

								<h2 className="link-g-ul">
									<a className="github-g-ul"
									   href="https://github.com/G-UL">
									   github.com/G-UL
									</a>
								</h2>

								<h2 className="link-g-ul">
									<a className="linkedin-g-ul"
									   href="https://www.linkedin.com/in/g-ul/">
									   linkedin.com/in/g-ul
									</a>
								</h2>
							</Col>
						</Col>

						<Col md={6}
							 sm={12}>
							<Row>
								<Col md={12}>
		 							<img alt="myPicture"
		 								 className="my-picture"
		 								 src={me} />
		 						</Col>
							</Row>

							<br />

							<Row>
								<Col md={12}>
									<p className="my-description">
										Hi, I am SeungHwan and full stack software developer in Toronto, Ontario, Canada.
									</p>

									<p className="my-description">
										I am interested in software deveopment and design that provides the best service experience for clients. Also, I am always interested in learning new things.
									</p>

									<p className="my-description">
										When I'm not coding, I play the drums, watch MMA, play Go against AI, and Starcraft 1!
									</p>

									<p className="my-description">
										Please feel free to get in touch!
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
};


export default Home;
